import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Login } from '../services/api'
import { AuthContext } from '../context/auth_context'
import { AlertContext } from '../context/alert_context'

export default function LoginView() {
  const navigate = useNavigate()

  const {setUser} = useContext(AuthContext)
  const {notify} = useContext(AlertContext)
  
  const [state, setState] = useState({
    email:'',
    password:''
  })

  const onLogin =async () => {
    let user = await Login({...state})
    console.log(user)
    if(user.status !== 200){
      notify({title:'Error!', message:'Ocurrio un error al iniciar sesión, por favor intenta de nuevo más tarde.', type:'error'})
      return
    }
    
    setUser({user:user.data})
    navigate('/user')
  }

  return (
    <div className='login_view container-fluid'>

      <div className='container'>
        <img className='d-block mx-auto pt-5' width={250} src='assets/images/logo.png'></img>
      </div>

      <div className='login_view_form'>

        <div className='mt-2'>
          <div className='text-gray'>Correo electrónico</div>
          <div>
            <input value={state.email} onChange={(evt)=>{setState({...state, email:evt.currentTarget.value})}} className='input--light' type='text'></input>
          </div>
        </div>

        <div className='mt-2'>
          <div className='text-gray'>Contraseña</div>
          <div>
            <input value={state.password} onChange={(evt)=>{setState({...state, password:evt.currentTarget.value})}} className='input--light' type='password'></input>
          </div>
        </div>

       <div className='mt-3'>
        <div className='bv-cta bv-cta--success w-100' onClick={()=>{
          onLogin();
          
        }}>
            <div>Entrar</div>
          </div>
       </div>

       <div className='mt-3'>
       <div className='text-decoration-underline text-gray cursor-pointer text-center'>
        ¿No tienes cuenta? Regístrate <span className='cursor-pointer text-green text-decoration-underline bolder' onClick={()=>{
          navigate('/register')
        }}>Aquí</span>
       </div>
        <div className='text-decoration-underline text-gray cursor-pointer text-center' onClick={()=>{
          navigate('/recoverpass')
        }}>¿Olvidaste tu contraseña?</div>
       </div>

      </div>

    </div>
  )
}
