import React, { useContext, useEffect, useState } from 'react'
import SideMenu from '../components/sidemenu'
import { useNavigate } from 'react-router-dom';
import { Service } from '../services/api';
import { AuthContext } from '../context/auth_context';
import Renderif from '../components/renderif';
import { AiFillCloseCircle } from 'react-icons/ai'

export default function HomeView() {
    const navigate = useNavigate();

    const { user, logout } = useContext(AuthContext)

    const [state, setState] = useState({
        planes: [],
        description_modal: false,
        plan: [],
        loading: false
    })

    useEffect(() => {


        init();

        return () => { }
    }, [])

    const init = async () => {
        setState({ ...state, loading: true })
        let _planes = await Service('GET', 'plans');

        if (_planes.status === 200) {
            setState({ ...state, planes: _planes.data.filter((plan) => plan.specialty === "NUTRITION") })
        } else if (_planes.status === 401) {
            logout();
            navigate('/')
        }
        console.log(_planes.data.filter((plan) => plan.specialty === "NUTRITION"))
    }

    const checkPlan = (plan) => {
        console.log(plan)

        let some = false;
        if (user?.subscriptions.length === 0) {
            return false
        }

        let _user_subs = []

        user?.subscriptions.map((sub) => {
            _user_subs.push(sub.name)
        })

        let _plan_subs = []

        plan.stripeSubscriptions.map((sub) => {
            _plan_subs.push(sub.stripeSubscription.name)
        })

        _user_subs.map((user_sub) => {
            if (_plan_subs.includes(user_sub)) {
                some = true;
            }
        })






        if (some) {
            return true;
        } else {
            return false
        }
    }

    return (
        <div className='container-fluid d-flex h-100'>

            <div>
                <SideMenu page={'home'}></SideMenu>
            </div>

            <Renderif isTrue={state.description_modal}>
                <div className='overlay d-flex justify-content-center align-items-center'>
                    <div className='bv-modal shadow'>
                        <div className='px-4 pt-3 d-flex align-items-center'>
                            <div className='f-16 text-gray'> </div>
                            <div className='col'></div>
                            <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                                setState({ ...state, description_modal: false })

                            }}> <AiFillCloseCircle size={24} color="#00BF6B"></AiFillCloseCircle></div>
                        </div>
                        <div className='px-4 pb-5'>
                            <div className='plan-card-desc' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${state.plan?.image?.name})` }}></div>
                            <div className='px-4 pt-3 d-flex align-items-center'>
                                <div className='f-16 text-green bolder p-1'>{state.plan?.title}</div>
                                <div className='col'></div>
                                <div className={`bv-cta bv-cta--success`} onClick={() => {
                                    navigate('/user/precios')
                                }}>
                                    Ir al Plan

                                </div>
                            </div>
                            <div className='mt-3  overflow-auto max-h-400'>
                                {state.plan?.descripcion}
                            </div>
                        </div>
                    </div>
                </div>
            </Renderif>

            <div className='col h-100 overflow-auto hide-scroll'>

                <div className='container-fluid bg-white sticky-top '>
                    <img className='m-3' width={120} src='../assets/images/logo.png'></img>
                </div>

                <div className='container-fluid container-xl mx-auto p-3'>
                    <div className='f-28 text-gray bold'>PLANES ALIMENTICIOS</div>
                    <div className='f-16 text-gray'>Tips y recetas sencillas elaboradas por nutriólogas certificadas</div>
                </div>

                <div className='container-fluid container-xl mx-auto px-3'>
                    <div className='row'>
                        {
                            state.planes.filter((plan) => plan.title === "Test My Diet").map((item, i) => {
                                return (
                                    <div key={i} className='col-12  col-xl-4 col-xxl-3 pe-2 pb-3'>
                                        <div className='plan-card' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${item?.image?.name})` }} onClick={() => {


                                            if (checkPlan(item)) {
                                                navigate('/user/plan', { state: { item } })
                                            } else {
                                                // navigate('/user/precios')
                                                setState({ ...state, description_modal: true, plan: item })
                                            }

                                        }}>
                                            <div className='layout-cover'></div>
                                            <div className='col'></div>
                                            <div className='p-3'>
                                                <div className='f-18 text-white bolder'>{item.title}</div>
                                                <div className='f-14 text-white'>{item.type}</div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='container-fluid container-xl mx-auto p-3'>
                    <div className='f-28 text-gray bold'>PLANES ESPECÍFICOS PARA CONDICIONES DE SALUD</div>
                    {/* <div className='f-16 text-gray'>Tips y recetas sencillas elaboradas por nutriólogas certificadas</div> */}
                </div>

                <div className='container-fluid container-xl mx-auto px-3'>
                    <div className='row'>
                        {
                            state.planes.filter((plan) => plan.title !== "Test My Diet" && plan.title !== "Salud 360").map((item, i) => {
                                return (
                                    <div key={i} className='col-12  col-xl-4 col-xxl-3 pe-2 pb-3'>
                                        <div className='plan-card' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${item?.image?.name})` }} onClick={() => {


                                            if (checkPlan(item)) {
                                                navigate('/user/plan', { state: { item } })
                                            } else {
                                                // navigate('/user/precios')
                                                setState({ ...state, description_modal: true, plan: item })
                                            }

                                        }}>
                                            <div className='layout-cover'></div>
                                            <div className='col'></div>
                                            <div className='p-3'>
                                                <div className='f-18 text-white bolder'>{item.title}</div>
                                                <div className='f-14 text-white'>{item.type}</div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='container-fluid container-xl mx-auto p-3'>
                    <div className='f-28 text-gray bold'>PLANES GENERALES DE SALUD</div>
                    {/* <div className='f-16 text-gray'>Tips y recetas sencillas elaboradas por nutriólogas certificadas</div> */}
                </div>

                <div className='container-fluid container-xl mx-auto px-3'>
                    <div className='row'>
                        {
                            state.planes.filter((plan) => plan.title === "Salud 360").map((item, i) => {
                                return (
                                    <div key={i} className='col-12  col-xl-4 col-xxl-3 pe-2 pb-3'>
                                        <div className='plan-card' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${item?.image?.name})` }} onClick={() => {


                                            if (checkPlan(item)) {
                                                navigate('/user/plan', { state: { item } })

                                            } else {
                                                // navigate('/user/precios')
                                                setState({ ...state, description_modal: true, plan: item })
                                            }

                                        }}>
                                            <div className='layout-cover'></div>
                                            <div className='col'></div>
                                            <div className='p-3'>
                                                <div className='f-18 text-white bolder'>{item.title}</div>
                                                <div className='f-14 text-white'>{item.type}</div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}
