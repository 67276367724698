import React from 'react'
import SideMenu from '../components/sidemenu'
import { AiOutlineTags } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi";

export default function ComidaDetail() {
    const navigate = useNavigate();
  return (
    <div className='container-fluid d-flex h-100'>

    <div>
        <SideMenu page={'comidas'}></SideMenu>
    </div>

    <div className='col'>

        <div className='container-fluid bg-white'>
            <img className='m-3' width={120} src='/assets/images/logo.png'></img>
        </div>

       
        
        <div className='container-fluid'>
            <div className='tip-full-aux' style={{backgroundImage: 'url(/assets/images/comida_1.jpeg)'}}></div>
        </div>

        <div className='container p-3'>
            <div className='cta-back' onClick={()=>{
                navigate(-1)
            }}>
                <div className='bv-icon bv-icon--mid'>
                    <BiArrowBack size={18} color='#00bf6b'></BiArrowBack>
                </div>
                <div className='f-14 text-green'>Regresar</div>
            </div>
            <div className='f-28 text-gray bold'>Salmon con verduras al horno</div>
      
        </div>
        <div className='container px-3'>
            <div className='tags'>
                <div className='bv-tag bv-tag--gray'>
                    <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                    <div className='text-gray pe-2'>Tip</div>
                </div>
                <div className='bv-tag bv-tag--gray'>
                    <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                    <div className='text-gray pe-2'>Tag</div>
                </div>
                <div className='bv-tag bv-tag--gray'>
                    <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                    <div className='text-gray pe-2'>Healthy</div>
                </div>
            </div>
            <div className='mt-3'>
            <span className='bolder'>Lorem Ipsum is simply</span> dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </div>

            <div className='f-16 bolder mt-3'>Why do we use it?</div>

            <div className='mt-3'>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </div>
        </div>
        <div className='container px-3 mt-3 d-none'>
            <div className='row'>
                <div className='col-3 pe-2'>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/1.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Plan General</div>
                        <div className='f-16 text-gray p-1'>Elimina la leche de vaca </div>
                    </div>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/4.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Sop</div>
                        <div className='f-16 text-gray p-1'>¿Qué puedo hacer por mi, que nadie más hará?</div>
                    </div>

                </div>
                <div className='col-3 pe-2'>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/2.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Endometriosis</div>
                        <div className='f-16 text-gray p-1'>¿Cómo mejorar la calidad de vida?</div>
                    </div>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/5.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Resistencia a la Insulina</div>
                        <div className='f-16 text-gray'>Evita estos alimentos en tu día </div>
                    </div>
                </div>
                <div className='col-3 pe-2'>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/3.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Sop</div>
                        <div className='f-16 text-gray p-1'>¿Qué puedo hacer por mi, que nadie más hará?</div>
                    </div>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/6.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Sop</div>
                        <div className='f-16 text-gray p-1'>¿Qué puedo hacer por mi, que nadie más hará?</div>
                    </div>
                </div>
                <div className='col-3 pe-2'>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/1.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Resistencia a la Insulina</div>
                        <div className='f-16 text-gray'>Evita estos alimentos en tu día </div>
                    </div>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/7.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Resistencia a la Insulina</div>
                        <div className='f-16 text-gray'>Evita estos alimentos en tu día </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
        
    </div>
  )
}
