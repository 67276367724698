
var weekday=new Array(7);
weekday[0]="Domingo";
weekday[1]="Lunes";
weekday[2]="Martes";
weekday[3]="Miercoles";
weekday[4]="Jueves";
weekday[5]="Viernes";
weekday[6]="Sabado";

var months=new Array(7);
months[0]="Enero";
months[1]="Febrero";
months[2]="Marzo";
months[3]="Abril";
months[4]="Mayo";
months[5]="Junio";
months[6]="Julio";
months[7]="Agosto";
months[8]="Septiembre";
months[9]="Octubre";
months[10]="Noviembre";
months[11]="Diciembre";
export const date = (_date) =>{
    return weekday[new Date(_date).getDay()] + " " + new Date(_date).getDate() +" "+ months[new Date(_date).getMonth()] +" "+ new Date(_date).getFullYear()
}
export const YYYYMMDD = (_date) =>{

 
    if(_date === null || _date === undefined){
        return '';
    }
    let aux = _date.split('T')[0].split('-')
    return aux[0] + '-' + aux[1] + '-' + aux[2]
}
Number.prototype.pad = function(size) {
    var s = String(this);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}
export const dateISO = (date) => {
    var day = date.getDate();
    var month = date.getMonth() + 1; //months are zero based
    var year = date.getFullYear();
    let time = date.toLocaleTimeString().replace(/(.*)\D\d+/, '$1');
    let date_parse = year + '-' + month.pad() + '-' + day.pad() + 'T' + time + ':00.000Z';
    return date_parse
}
