import React from 'react'
import SideMenu from '../components/sidemenu'
import { AiOutlineTags } from 'react-icons/ai'
import { useLocation, useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi";

export default function TipDetail() {
    const navigate = useNavigate();
    const location = useLocation();
    const {tip, includes} = location.state
    console.log(tip)


    const buildList = (content) => {

     
      
        return content.map((cont)=>{
              
                return (
                    <li>{cont.content[0].content[0].value}</li>
                )
        })

 
    }
  return (
    <div className='container-fluid d-flex h-100'>

    <div>
        <SideMenu page={'home'}></SideMenu>
    </div>

    <div className='col h-100 overflow-auto hide-scroll'>

        <div className='container-fluid bg-white sticky-top'>
            <img className='m-3' width={120} src='/assets/images/logo.png'></img>
        </div>

       
        
        <div className='container-fluid'>
            <div className='tip-full' style={{backgroundImage: `url(${includes?.fields?.file?.url})`}}></div>
        </div>

        

        <div className='container p-3'>
            <div className='cta-back' onClick={()=>{
                navigate(-1)
            }}>
                <div className='bv-icon bv-icon--mid'>
                    <BiArrowBack size={18} color='#00bf6b'></BiArrowBack>
                </div>
                <div className='f-14 text-green'>Regresar</div>
            </div>
            <div className='f-28 text-gray bold'>{tip?.fields?.title}</div>
            <div className='f-16 text-gray'>{includes?.fields?.description}</div>
        </div>
        <div className='container px-3'>

            <div className='tags'>

            {tip?.metadata?.tags.map((item, i)=>{
                return (
                    <div key={i} className='bv-tag bv-tag--gray'>
                        <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                        <div className='text-gray pe-2'>{item?.sys?.id}</div>
                    </div>
                )
            })}
            
            
             
            </div>

            <div className='mt-3'>

                {
                    tip?.fields?.description?.content.map((content)=>{
                        // console.log(content.nodeType)
                        if(content.nodeType === "paragraph"){
                            let parrafo = ''
                            content.content.map((cont)=>{
                                    parrafo = parrafo + cont.value
                            })
                            return (
                                <div>{parrafo}</div>
                            )
                        }

                        if(content.nodeType === "unordered-list"){
                            return (
                                <div>{buildList(content.content)}</div>
                            )
                        }
                    })
                }
           
            </div>

          

          
        </div>

    

        <div className='container px-3 mt-3 d-none'>
            <div className='row'>
                <div className='col-3 pe-2'>
                    <div className='tip-card'>
                        <img className='w-100' src={includes?.fields?.file?.url}></img>
                        <div className='f-16 text-green bolder p-1'>Plan General</div>
                        <div className='f-16 text-gray p-1'>Elimina la leche de vaca </div>
                    </div>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/4.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Sop</div>
                        <div className='f-16 text-gray p-1'>¿Qué puedo hacer por mi, que nadie más hará?</div>
                    </div>

                </div>
                <div className='col-3 pe-2'>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/2.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Endometriosis</div>
                        <div className='f-16 text-gray p-1'>¿Cómo mejorar la calidad de vida?</div>
                    </div>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/5.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Resistencia a la Insulina</div>
                        <div className='f-16 text-gray'>Evita estos alimentos en tu día </div>
                    </div>
                </div>
                <div className='col-3 pe-2'>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/3.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Sop</div>
                        <div className='f-16 text-gray p-1'>¿Qué puedo hacer por mi, que nadie más hará?</div>
                    </div>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/6.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Sop</div>
                        <div className='f-16 text-gray p-1'>¿Qué puedo hacer por mi, que nadie más hará?</div>
                    </div>
                </div>
                <div className='col-3 pe-2'>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/1.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Resistencia a la Insulina</div>
                        <div className='f-16 text-gray'>Evita estos alimentos en tu día </div>
                    </div>
                    <div className='tip-card'>
                        <img className='w-100' src='/assets/images/7.jpg'></img>
                        <div className='f-16 text-green bolder p-1'>Resistencia a la Insulina</div>
                        <div className='f-16 text-gray'>Evita estos alimentos en tu día </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
        
    </div>
  )
}
