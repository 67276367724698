export const primary  = '#30B7E7'

export const  gray00 = "#F5F6F7";
export const  gray10 = "#EBEDF0";
export const  gray20 = "#DADDE1";
export const  gray30 = "#BEC3C9";
export const  gray40 = "#8D949E";
export const  gray70 = "#606770";
export const  gray80 = "#444950";
export const  gray90 = "#303338";
export const  gray100 = "#1C1E21";
export const  green = "#37D4AD";
export const  yellow = "#EEDD39";