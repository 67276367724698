import React, { useContext, useEffect, useState } from 'react'
import SideMenu from '../components/sidemenu'
import { Await, useLocation, useNavigate } from 'react-router-dom';
import { BiArrowBack, BiRefresh } from "react-icons/bi";
import { AiFillCloseCircle, AiOutlineTag, AiOutlineTags } from 'react-icons/ai';
import { Service } from '../services/api';
import { YYYYMMDD, date } from '../utils/utils';
import { AlertContext } from '../context/alert_context';
import Renderif from '../components/renderif';
import { AuthContext } from '../context/auth_context';
import { MdOutlineSwapHorizontalCircle } from "react-icons/md";
import Loader from '../components/loader';

export default function ComidasView() {

  const location = useLocation()
  const { user, logout } = useContext(AuthContext)

  let _day = location.state?.day_active

  _day = _day !== undefined ? _day : new Date()

  const navigate = useNavigate();
  const { notify } = useContext(AlertContext)
  const [state, setState] = useState({
    day_active: _day,
    recipe_active: 0,
    allRecipes: [],
    plan_comidas: [],
    recipes: null,
    allow_save: false,
    loading: true,
    menu_options: false,
    category_aliments:[],
    show_swap:false,
    show_swap_items:false,
    aliments:[],
    show_valoracion:false,
    show_swap_recipe:false,
    category_selected:null,
    reicpe_swap_time:null,
    valoracion_comment:'',
    history:[],
    loading_history:false
  })

  useEffect(() => {
    // init()
    return () => { }
  }, [])

  useEffect(() => {




    const init = async () => {

      setState({ ...state, loading: true })

      
      let recipes = await Service('GET', 'recipes');
      if (recipes.status === 401) {
        logout();
        navigate('/')
      }
      let _plan_comidas = await Service('GET', `patients/${user?.id}/meal-plans`);
      let __aliments = await Service('GET', `aliments`);
      state.aliments = __aliments.data
      if (_plan_comidas.status === 401) {
        logout();
        navigate('/')
      }
      let _aliments = [];
      recipes.data.forEach((element) => element.ingredients.forEach((alimento) => {
        let _categoria = _aliments.find((find) => find.Categoria === alimento.aliment.grupo + alimento.aliment.subGrupo);
        if (!_categoria) {
          _aliments.push({ Categoria: alimento.aliment.grupo + alimento.aliment.subGrupo, Almentos: [alimento.aliment.alimentos] })
        }
        else {
          // _categoria.Almentos.push(alimento.aliment.alimentos)
          if (!_categoria.Almentos.find((al) => al === alimento.aliment.alimentos)) {
            _categoria.Almentos.push(alimento.aliment.alimentos)
          }
        }
      }
      )
        // 
      );

      console.log(_aliments)
      // _aliments.forEach((categoria) => categoria.Almentos.forEach((alimentos) => console.log(alimentos)));
      state.category_aliments=_aliments;
      state.allRecipes = recipes.data;
      state.plan_comidas = _plan_comidas.data
      console.log(state.allRecipes);
      // state.day_active = _day


      let _recipes = {
        breakfast: null
      };
      state.plan_comidas.map((item) => {


        if (YYYYMMDD(item.day) === YYYYMMDD(state.day_active.toISOString())) {
          _recipes = item;
        }
      })

      let random;

      if (_recipes.breakfast === null) {

        _recipes.day = state.day_active.toISOString()

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Desayuno").length)
        _recipes.breakfast = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Desayuno")[random]
        console.log(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Desayuno")[random]);

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Comida").length)
        _recipes.meal = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Comida")[random]
        console.log(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Comida")[random]);

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Cena").length)
        _recipes.dinner = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Cena")[random]
        console.log(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Cena")[random]);

        _recipes.id = 0
        _recipes.userId = 6
        state.allow_save = true

      }

      state.recipes = _recipes;
      state.recipe_active = _recipes?.breakfast
      // state.show_swap = false;
      state.loading = false

      setState({ ...state })
    }
    init();



    return () => { }

  }, [state.day_active])

  useEffect(()=>{
      const init = async () => {
        setState({...state, loading_history:true})

        if(state.recipe_active !== 0 && state.recipe_active !== null){
          let history = await Service('GET', `recipes-history/${state.recipe_active.id}`);
          console.log(history)
          state.history = history.data
          setState({...state, loading_history:false})
        }
      }
      init()
      return () => {}

  },[state.recipe_active])





  const _init = async () => {
    // let _recipes = await Service('GET','recipes');
    // let _plan_comidas = await Service('GET','patients/6/meal-plans');


    // state.allRecipes = _recipes.data;
    // state.plan_comidas = _plan_comidas.data
    // state.day_active = _day

    // setState({...state, allRecipes:_recipes.data, plan_comidas:_plan_comidas.data, day_active:_day });


  }


  const onSave = async () => {
    setState({ ...state, loading: true })
    let body = {
      day: YYYYMMDD(state.day_active.toISOString()),
      breakfast: state.recipes.breakfast.id,
      meal: state.recipes.meal.id,
      dinner: state.recipes.dinner.id
    }

    if (state.recipes.id === 0) {

      let save = await Service('POST', `patients/${user.id}/meal-plans`, body)
      if (save.status === 201) {
        notify({ title: "Listo!", message: "Se ha guardado este plan de comidas.", type: 'success' })
      } else if (save.status === 401) {
        logout();
        navigate('/')
      } else {
        notify({ title: "Error!", message: "No ha sido posible guardar ese plan de comidas.", type: 'error' })
      }

    } else {

      let update = await Service('PUT', `patients/${user.id}/meal-plans/${state.recipes.id}`, body)
      console.log(update)
      if (update.status === 200) {
        notify({ title: "Listo!", message: "Se ha guardado este plan de comidas.", type: 'success' })
      } else if (update.status === 401) {
        logout();
        navigate('/')
      } else {
        notify({ title: "Error!", message: "No ha sido posible guardar ese plan de comidas.", type: 'success' })
      }
    }
    setState({ ...state, loading: false })
  }



  function rand(max) {
    return Math.floor(Math.random() * max);
  }


  const swap_recipe = (time) => {
    state.allow_save = true
    let random;
    switch (time) {
      case 0:

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Desayuno").length)
        console.log(state.recipes.breakfast)
        state.recipes.breakfast = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Desayuno")[random]
        state.recipe_active = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Desayuno")[random]

        setState({ ...state })
        return
      case 1:

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Comida").length)
        state.recipes.meal = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Comida")[random]
        state.recipe_active = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Comida")[random]
        setState({ ...state })
        return
      case 2:

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Cena").length)
        state.recipes.dinner = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Cena")[random]
        state.recipe_active = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Cena")[random]
        setState({ ...state })

        return

      default:
        break;
    }
  }

  const filterAlimetnsType = (aliment) => {

    let _alimentsFiltered = state.aliments.filter((item)=>{
      if(item.grupo === aliment.aliment.grupo  && item.subGrupo === aliment.aliment.subGrupo){
        return true
      }
      return false
    })

    return (_alimentsFiltered)

   
  }

  const onValorarRecipe = async (valoracion) => {
      setState({...state, loading:true})
      let body = {
        score:valoracion,
        comments:state.valoracion_comment
      }
      let _valorar  = await Service('POST', `recipes/${state.recipe_active.id}/comments`, body)
      console.log(_valorar)
      if(_valorar.status === 201){
        notify({ title: "Listo!", message: "¡Valoracion enviada!", type: 'success' })
        setState({...state, show_valoracion:false, loading:false, valoracion_comment:''})
      }else{
        notify({ title: "Valoración!", message: "No ha sido posible valorar la receta.", type: 'error' })
        setState({...state, show_valoracion:false, loading:false, valoracion_comment:''})
      }
  }

  const onSwapAliment = async (aliment, newAliment, recipe) => {
    setState({...state, loading:true})
    let body = {
      alimentId:aliment,
      alimentNewId:newAliment
    }
    let _swap  = await Service('POST', `recipes-history/${recipe}`, body)
    console.log(_swap)

    if(_swap.status === 201){
      notify({ title: "!Ingredientes¡", message: "Ingrediente cambiado exitosamente", type: 'success' })

    }else{
      notify({ title: "!Ingredientes¡", message: "No ha sido posible cambiar este ingrediente.", type: 'error' })

    }
 
    setState({...state, show_swap_items:null, show_swap:false, loading:false})
  }



  return (
    <div className='container-fluid d-flex h-100'>
        <Renderif isTrue={state.loading}>
                <Loader></Loader>
            </Renderif>
      <Renderif isTrue={state.show_valoracion}>
      <div className='overlay d-flex justify-content-center align-items-center' onClick={(evt)=>{
        setState({...state, show_valoracion:false})
      }}>
        <div className='bv-modal shadow' onClick={(evt)=>{evt.stopPropagation()}}>
           
           <div className='d-flex flex-column align-items-center justify-content-center py-3'>
           <div className='f-24 text-center text-gray bold'>¿Qué te pareció <br></br> preparar esta receta?</div>
            <div className='f-16 text-gray'>¡Queremos conocer tu experiencia¡</div>

            

            
            <div className='d-flex flex-column mt-3'>
              <textarea value={state.valoracion_comment} onChange={(evt)=>{
                setState({...state, valoracion_comment:evt.currentTarget.value})
              }} className='input--light mb-3 pt-1' rows={3} placeholder='Envianos tus comentarios.'></textarea>
              <div className='d-flex'>
                <div className='bv-cta bv-cta--success me-2 ' onClick={()=>{
                  onValorarRecipe('PODRIA_MEJORAR')
                //  setState({...state, show_valoracion:false})
                }}>
                  <div>Podría mejorar</div>
                </div>
                <div className='bv-cta bv-cta--success me-2 ' onClick={()=>{
                  onValorarRecipe('ESTUVO_BIEN')
                }}>
                  <div>Estuvo bien</div>
                </div>
                <div className='bv-cta bv-cta--success ' onClick={()=>{
                      onValorarRecipe('FUE_GENIAL')
                }}>
                  <div>!Fue genial¡</div>
                </div>
              </div>
            </div>
           </div>

        </div>

      </div>
      </Renderif>

      <Renderif isTrue={state.show_swap_recipe}>
      <div className='overlay d-flex justify-content-center align-items-center' onClick={(evt)=>{
        setState({...state, show_valoracion:false})
      }}>
        <div className='bv-modal hide-scroll shadow' onClick={(evt)=>{evt.stopPropagation()}}>
           
          <div className='d-flex flex-column  pt-3'>
         
            <div className=' px-3 d-flex align-items-center'>
              <div className='f-24  text-green bold'>Cambio de receta</div>
                <div className='col'></div>
                <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                    setState({ ...state, show_swap_recipe: false })

                }}> <AiFillCloseCircle size={24} color="#00BF6B"></AiFillCloseCircle></div>
            </div>
            <div className='f-16 text-gray px-3'>Plan Alimenticio: </div>
            <div className='f-16 text-gray mt-3 px-3'>Selecciona la receta por la que desees.</div>

            <div className='px-3'>
              <div className='bv-tag bv-tag--gray'>
                <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                <div className='text-gray pe-2'>{state.category_selected}</div>
              </div>
              <div className='bv-tag bv-tag--gray'>
                <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                {/* <div className='text-gray pe-2'>{state.recipe_active?.mealTime[0]}</div> */}
              </div>
            </div>

                  {
                    state.allRecipes.filter((item) => {
                      if(item.mealCategories.includes(state.category_selected) && item.mealTime.includes(state.recipe_active.mealTime[0])){
                        return true
                      }
                      if(item.mealSubCategories.includes(state.category_selected) && item.mealTime.includes(state.recipe_active.mealTime[0])){
                        return true
                      }
                   
                      return false
                    }).map((recipe, i)=>{
                      console.log(state.category_selected)
                      console.log(recipe)
                      return (
                      <div key={i} className='tip-card tip-card--horizontal cursor-pointer m-2' onClick={() => {
                          // setState({ ...state, recipe_active: state.recipes?.breakfast })

                          switch (state.recipe_active.mealTime[0]) {
                            case 'Desayuno':
                              state.recipes.breakfast = recipe;
                              state.recipe_active = recipe;
                              setState({...state, show_swap_recipe:false})
                              break;
                            case 'Comida':
                              state.recipes.meal = recipe;
                              state.recipe_active = recipe;
                              setState({...state, show_swap_recipe:false})
                              break;
                            case 'Cena':
                              state.recipes.dinner = recipe;
                              state.recipe_active = recipe;
                              setState({...state, show_swap_recipe:false})
                              break;
                            default:
                              break;
                          }

                          console.log(recipe)
                        }}>
                          <div className='tip-full-image' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${recipe.image?.name})` }}></div>
                          
                   
                          <div className='col ps-2'>
                            <div className='f-16 text-green bolder p-1'>{recipe?.name}</div>
                            <div className='f-14 text-gray p-1 text-ellipsis-2'>{recipe?.preparation}</div>
                          </div>
                      </div>
                      )
                    })
                  }


         
          
           </div>

        </div>

      </div>
      </Renderif>
      
      <div>
        <SideMenu page={'comidas'}></SideMenu>
      </div>

      <div className='col h-100 overflow-auto hide-scroll'>

        <div className='container-fluid bg-white sticky-top'>
          <img className='m-3' width={120} src='../assets/images/logo.png'></img>
        </div>

        <div className='container-fluid pb-5'>

          <div className='container-fluid container-xl mx-auto p-3'>




            <div className='d-flex flex-wrap align-items-center'>

              <div>
                <div className='f-28 text-gray bold line-0'>Comidas</div>
                <div className='d-flex justify-content-center align-items-center'>

                  <div className='f-14 text-gray line-0'>{YYYYMMDD(state.day_active?.toISOString())}</div>

                  <div className='f-14 text-green line-0 text-decoration-underline cursor-pointer ps-2' onClick={() => {
                    navigate('/user/comida/semana')
                  }}>Ver semana</div>
                </div>
              </div>

              <div className='col'></div>

              <div className='col-12 col-md d-flex align-items-center mt-3 mt-md-0'>

                <div className='col'></div>

                <Renderif isTrue={state.loading}>
                  <div className='pe-3'>
                    <div className="spinner-border"></div>
                  </div>

                </Renderif>

                <div className='pe-3'>


                  <input type='date' value={state.day_active?.toISOString().split('T')[0]} onChange={(evt) => {

                    setState({ ...state, day_active: new Date(evt.currentTarget.value) })
                    console.log(evt.currentTarget.value)

                  }} className='input--light'></input>
                </div>

                <div className={`bv-cta bv-cta--${state.allow_save === true ? 'success' : 'disabled'}`} onClick={() => {
                  onSave()
                }}>
                  Guardar

                </div>
              </div>


            </div>

          </div>


          <div className='container-fluid container-xl mx-auto p-3'>
            <div className='row'>

              <div className='col-12 col-lg-3 d-flex  flex-wrap pe-2'>

                <div className='col-12 col-md-4 col-lg-12'>
                  <div className='d-flex align-items-center'>
                    <div className='f-16 text-gray bold'>Desayuno</div>
                    <div className='col'></div>
                    <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                      swap_recipe(0)
                    }}>
                      <BiRefresh size={30} color='#00bf6b'></BiRefresh>
                    </div>
                  </div>

                  <div className='tip-card cursor-pointer' onClick={() => {
                    setState({ ...state, recipe_active: state.recipes?.breakfast })
                  }}>
                    <div className='tip-full-image' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.breakfast?.id)?.image?.name})` }}></div>

                    {/* <img className='w-100 cursor-pointer' src={`https://mydiet-assets-2023.s3.amazonaws.com/${state.recipes?.breakfast.image}`}></img> */}

                    <div className='f-16 text-green bolder p-1'>{state.recipes?.breakfast?.name}</div>
                    <div className='f-14 text-gray p-1 text-ellipsis-2'>{state.recipes?.breakfast?.preparation}</div>
                  </div>
                </div>

                <div className='col-12 col-md-4 col-lg-12'>
                  <div className='d-flex align-items-center'>
                    <div className='f-16 text-gray bold'>Comida</div>
                    <div className='col'></div>
                    <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                      swap_recipe(1)
                    }}>
                      <BiRefresh size={30} color='#00bf6b'></BiRefresh>
                    </div>
                  </div>

                  <div className='tip-card cursor-pointer' onClick={() => {
                    console.log(state.recipes?.mel)
                    setState({ ...state, recipe_active: state.recipes?.meal })
                  }}>
                    <div className='tip-full-image' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.meal?.id)?.image?.name})` }}></div>

                    <div className='f-16 text-green bolder p-1'>{state.recipes?.meal?.name}</div>
                    <div className='f-14 text-gray p-1 text-ellipsis-2'>{state.recipes?.meal?.preparation}</div>
                  </div>
                </div>

                <div className='col-12 col-md-4 col-lg-12'>

                  <div className='d-flex align-items-center'>
                    <div className='f-16 text-gray bold'>Cena</div>
                    <div className='col'></div>
                    <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                      swap_recipe(2)
                    }}>
                      <BiRefresh size={30} color='#00bf6b'></BiRefresh>
                    </div>
                  </div>

                  <div className='tip-card cursor-pointer' onClick={() => {
                    setState({ ...state, recipe_active: state.recipes?.dinner })
                  }}>
                    <div className='tip-full-image' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.dinner?.id)?.image?.name})` }}></div>

                    <div className='f-16 text-green bolder p-1'>{state.recipes?.dinner?.name}</div>
                    <div className='f-14 text-gray p-1 text-ellipsis-2'>{state.recipes?.dinner?.preparation}</div>
                  </div>

                </div>

              </div>

              <div className='col-12 col-lg-9 px-3'>

                <Renderif isTrue={state.loading_history}>
                  <div className='d-flex justify-content-center align-items-center mt-5 mt-5'>
                    <div className="spinner-border"></div>
                  </div>
                </Renderif>

                <Renderif isTrue={!state.loading_history}>


                <div className='f-16 text-gray bold opacity-0'>Comidas</div>
                <div className='container-fluid'>
                  <div className='tip-full-aux' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipe_active?.id)?.image?.name})` }}></div>
                </div>


                <div className='container-fluid'>

                  <div className='mt-3'>

                   <div className='d-flex flex-row'>
                    <div className='f-16 text-green bolder p-1'>{state.recipe_active?.name}</div>
                    <div className='col'></div>
                    <div className='d-flex align-items-center '>
                      <div className='f-14 text-green p-1 text-decoration-underline cursor-pointer' onClick={()=>{
                        setState({...state, show_valoracion:true})
                      }}>Realizaste tu comida</div>
                      <div><img width="60%" src='/assets/images/like_meal.svg'></img></div>
                    </div>
                   </div>

                    <div className='tags'>
                      {state.recipe_active?.mealCategories?.map((item, i) => {
                        return (
                          <div key={i} className='bv-tag bv-tag--gray cursor-pointer' onClick={()=>{
                            setState({...state, show_swap_recipe:true, category_selected:item})
                          }}>
                            <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                            <div className='text-gray pe-2'>{item}</div>
                          </div>
                        )
                      })}
                      {state.recipe_active?.mealSubCategories?.map((item, i) => {
                        return (
                          <div key={i} className='bv-tag bv-tag--gray cursor-pointer' onClick={()=>{
                            setState({...state, show_swap_recipe:true, category_selected:item})
                          }}>
                            <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                            <div className='text-gray pe-2'>{item}</div>
                          </div>
                        )
                      })}
                      {state.recipe_active?.mealTime?.map((item, i) => {
                        return (
                          <div key={i} className='bv-tag bv-tag--gray cursor-pointer' onClick={()=>{
                            setState({...state, show_swap_recipe:true, category_selected:item})
                          }}>
                            <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                            <div className='text-gray pe-2'>{item}</div>
                          </div>
                        )
                      })}


                    </div>
                  </div>

                  <div className='row'>

                    <div className='col-4 py-3'>

                      <div className='f-16 bold'>Ingredientes</div>
                      <div className='mt-3'>
                      <Renderif isTrue={state.show_swap}>
                        <div className='overlay' onClick={()=>{
                          setState({...state, show_swap:false, show_swap_items:null})
                        }}></div>
                      </Renderif>
                        <ul className='list-style-none p-0'>
                          {state.recipe_active?.ingredients?.map((item, i) => {
                           
                              let _swaped_items = state.history.filter((_item) => _item.alimentId === item.aliment.id);
                              let _newitem = null;
                              if(_swaped_items.length > 0){
                                let _newingredient = _swaped_items[_swaped_items.length - 1]
                                _newitem = state.aliments.find((aliment) => aliment.id === _newingredient.alimentNewId)
                              }
                             
                              
                           
                            
                            return (

                              <li className='position-relative' key={i} onClick={() => {
                                // let elements = document.getElementsByClassName(`list-${i}`);
                                // let txt = document.getElementsByClassName(`ingredient${i}`);
                                // if (elements.length > 0) {
                                //   if (elements[0].classList.contains("hide")) {
                                //     elements[0].classList.remove("hide");

                                //     txt[0].classList.remove("text-gray")
                                //     txt[0].classList.add("text-green");
                                //   }
                                //   else {
                                //     elements[0].classList.add("hide");
                                //     txt[0].classList.remove("text-green")
                                //     txt[0].classList.add("text-gray");
                                //   }
                                // }
                              }}>

                                <div className='d-flex flex-row'>
                                <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                                  // filterAlimetnsType(item);
                                
                                  setState({...state, show_swap:true, show_swap_items:i})
                                  // let elements = document.getElementsByClassName(`list-${i}`);
                                  // let txt = document.getElementsByClassName(`ingredient${i}`);
                                  // if (elements.length > 0) {
                                  //   if (elements[0].classList.contains("hide")) {
                                  //     elements[0].classList.remove("hide");
  
                                  //     txt[0].classList.remove("text-gray")
                                  //     txt[0].classList.add("text-green");
                                  //   }
                                  //   else {
                                  //     elements[0].classList.add("hide");
                                  //     txt[0].classList.remove("text-green")
                                  //     txt[0].classList.add("text-gray");
                                  //   }
                                  // }
                                }}>
                                  <MdOutlineSwapHorizontalCircle size={20} color='#666' ></MdOutlineSwapHorizontalCircle>
                                </div>
                                <Renderif isTrue={_newitem !== null}>
                                  <div className={`text-gray ingredient${i}`}>{(_newitem?.cantidadSugeridaNum)} {_newitem?.unidad}. {_newitem?.alimentos}</div>
                                </Renderif>
                                <Renderif isTrue={_newitem === null}>
                                  <div className={`text-gray ingredient${i}`}>{(item.aliment.cantidadSugeridaNum * item.quantity)} {item.aliment.unidad}. {item.aliment.alimentos}</div>
                                </Renderif>
                              
                               
                                </div>
                                {/* hide list-${i} */}
                                <Renderif isTrue={state.show_swap_items === i}>
                                <div className={`bv-list-option`}>
                                  <ul className='list-style-none p-2'>
                                    {filterAlimetnsType(item).map((ingre, j)=>{
                                      

                                      return (
                                        <li key={j}>
                                            <div className='cursor-pointer highlight-hover--light py-1' onClick={async ()=>{

                                              console.log(item.recipeId)
                                              console.log(ingre.id)
                                              
                                              // state.recipe_active.ingredients[i] = ingre

                                            

                                            
                                              await onSwapAliment(item.aliment.id, ingre.id, item.recipeId)

                                              item.aliment = ingre;
                                              item.alimentId = ingre.id

                                            }}>{ingre.alimentos}</div>
                                          </li>
                                      )
                                    })}
                                    {/* {
                                      state.category_aliments.find((element) => element.Categoria === (item.aliment.grupo+item.aliment.subGrupo)).Almentos.map((ingre) => {
                                        console.log(state.category_aliments.find((element) => element.Categoria === (item.aliment.grupo+item.aliment.subGrupo)))
                                        return(
                                          <li>
                                            <div className='cursor-pointer highlight-hover--light py-1' onClick={()=>{
                                              console.log(item)
                                              console.log(ingre)
                                              setState({...state, show_swap_items:null, show_swap:false})
                                            }}>{ingre}</div>
                                          </li>
                                        )
                                      })
                                    } */}
                                  </ul>
                                </div>
                                </Renderif>

                              </li>
                            )
                          })}
                          {/* <li><div className='text-gray'>1 Aceite de soja</div></li>
                                  <li><div className='text-gray'>3 Hongos</div></li>
                                  <li><div className='text-gray'>1/2 Boleto</div></li>
                                  <li><div className='text-gray'>1 Seta de chopo</div></li>
                                  <li><div className='text-gray'>8 Cebolla caramelizada</div></li> */}
                        </ul>

                      </div>
                    </div>

                    <div className='col-8 py-3'>

                      <div className='f-16 bold'>Preparación</div>
                      <div className='mt-3 text-gray'>

                        {state.recipe_active?.preparation}
                      </div>
                    </div>
                  </div>




                </div>

                </Renderif>


              </div>

              {/* <div className='col-2 pe-2'>
                        <div className='tip-card'>
                            <img className='w-100' src='assets/images/3.jpg'></img>
                            <div className='f-14 text-green bolder p-1'>Endometriosis</div>
                            <div className='f-12 text-gray p-1'>¿Cómo mejorar la calidad de vida?</div>
                        </div>
                        <div className='tip-card'>
                            <img className='w-100' src='assets/images/1.jpg'></img>
                            <div className='f-14 text-green bolder p-1'>Resistencia a la Insulina</div>
                            <div className='f-12 text-gray'>Evita estos alimentos en tu día </div>
                        </div>
                    </div> */}

            </div>
          </div>

        </div>



      </div>

    </div>
  )
}
