import React, { useContext, useState } from 'react'
import SideMenu from '../components/sidemenu'
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi";
import { AiOutlineTag, AiOutlineTags } from 'react-icons/ai';
import { AlertContext } from '../context/alert_context';

import Renderif from '../components/renderif';
import { GoDotFill, GoStarFill } from "react-icons/go";
import { GoStar } from "react-icons/go";

import { HiOutlineVideoCamera } from 'react-icons/hi2';
import { AuthContext } from '../context/auth_context';
import { Service } from '../services/api';
import { YYYYMMDD } from '../utils/utils';


export default function ComidasSemanaView() {

    const {notify} = useContext(AlertContext)
    const {user, logout} = useContext(AuthContext)
    const navigate = useNavigate();
    const [state, setState] = useState({
        loading:false,
        init_date: new Date(),
        citas:[],
        show_agendar_cita:false,
        tab:0,
        plan_comidas:[],
        loading:true,
        allRecipes:[]

     })



    const init = async () => {
      
        let _recipes = await Service('GET','recipes');
        if(_recipes.status===401){
            logout();
            navigate('/')
        }
        let _plan_comidas = await Service('GET',`patients/${user.id}/meal-plans`);
        if(_plan_comidas.status===401){
            logout();
            navigate('/')
        }
        console.log(_plan_comidas)
  
        state.allRecipes = _recipes.data;
  
        console.log(YYYYMMDD(_plan_comidas.data[0].day))
        // console.log(YYYYMMDD(state.day_active.toISOString()))
  
  
        
  
  
        setState({...state, plan_comidas:_plan_comidas.data, loading:false, day_active:new Date()});
      }
      useState(()=>{

        init();
        return () => {}
    },[])

    // const getCitas = async () => {
    //     setState({...state, loading:true})
    //     let _citas = await Service('GET', 'Appointment');
    //     let today = new Date();
    //     let next_citas = _citas.data.data.filter((cita)=> new Date(cita.dateOfEvent) > today).sort((citaA, citaB)=> Number(new Date(citaA.dateOfEvent)) - Number(new Date(citaB.dateOfEvent)))

    //     setState({
    //         ...state, 
    //         citas:next_citas,
    //         loading:false
    //     })
    // }

    

    const addDays = (days, date) => {

        date.setDate(date.getDate() + days);
        return date;
    }
    const subDays = (days, date) =>{
        date.setDate(date.getDate() - days);
        return date;
    }

  
    // let date = new Date();
    // console.log(date.addDays(5));

 
    const y = [0,30, 60, 90, 120, 150]
    const time_mock = [
        "2024-03-08T09:00:00",
        "2024-03-09T10:00:00",
        "2024-03-10T11:00:00",
        "2024-03-11T12:00:00",
        "2024-03-12T13:00:00",
        "2024-03-13T14:00:00",
        "2024-03-14T15:00:00",
        "2024-03-15T16:00:00",
        "2024-03-16T17:00:00",
    ]
    const time = [
        '9:00 AM',
        '10:00 AM',
        '11:00 AM',
    ]
    const times = [
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
    ]

    var weekday=new Array(7);
    weekday[0]="Domingo";
    weekday[1]="Lunes";
    weekday[2]="Martes";
    weekday[3]="Miercoles";
    weekday[4]="Jueves";
    weekday[5]="Viernes";
    weekday[6]="Sabado";

    var months=new Array(7);
    months[0]="Enero";
    months[1]="Febrero";
    months[2]="Marzo";
    months[3]="Abril";
    months[4]="Mayo";
    months[5]="Junio";
    months[6]="Julio";
    months[7]="Agosto";
    months[8]="Septiembre";
    months[9]="Octubre";
    months[10]="Noviembre";
    months[11]="Diciembre";

    const getRestDays = (day, date, tile_index,) => {
     

        // if(tile_index === 6){
        //     console.log(tile_index)
        //     console.log(day)
        // }


        
        // if(tile_index === day){
            
        //     return date;
        // }
       

        let a = new Date(state.init_date);
        

        return  subDays(((day ) - tile_index),a).getDate();



        


    }
    const getTileDate = (day, date, tile_index,) => {
     

        // if(tile_index === 6){
        //     console.log(tile_index)
        //     console.log(day)
        // }


        
        // if(tile_index === day){
            
        //     return date;
        // }
       

        let a = new Date(state.init_date);
        

        return  subDays(((day ) - tile_index),a)



        


    }
    const TileRecipeCheck = (tile_date) => {

        let _recipes; 

          state.plan_comidas.map((item)=>{
            console.log(item)
       
            if(YYYYMMDD(item.day) === YYYYMMDD(tile_date.toISOString())){
              _recipes = item;
            }
          })
    
          
        console.log(_recipes)
        return _recipes;

      
    }

  return (
    <div className='container-fluid d-flex h-100'>

    <div>
        <SideMenu page={'comidas'}></SideMenu>
    </div>

    <div className='col'>

        <div className='container-fluid bg-white'>
            <img className='m-3' width={120} src='/assets/images/logo.png'></img>
        </div>

        <div className='container  p-3'>

    


           <div className='d-flex align-items-center'>
           <div>
           <div className='cta-back' onClick={()=>{
                navigate(-1)
            }}>
                <div className='bv-icon bv-icon--mid'>
                    <BiArrowBack size={18} color='#00bf6b'></BiArrowBack>
                </div>
                <div className='f-14 text-green'>Regresar</div>
            </div>
              <div className='f-28 text-gray bold line-0'>Comidas</div>
              <div className='d-flex justify-content-center align-items-center'>

                <div className='f-14 text-gray line-0'>Domingo 17 al Sabado 23 </div>
                
               
              </div>
           </div>
           <div className='col'></div>

            <Renderif isTrue={state.loading}>
                <div className='pe-4'>
                    <div class="spinner-border"></div>
                </div>
            </Renderif>
            
        
          

           </div>
        </div>


        {/* <div className='container px-3'>
            <div className='row'>
                <div className='f-16 bold text-gray ps-2'>Desayuno</div>
                <div className='d-flex'>
                {
                    ['Domingo','Lunes','Martes','Miercoles','Jueves','Viernes','Sabado'].map((item, i)=>{
                        return(
                            <div key={i} className='col p-2'>
                                <div className='f-14 text-gray mb-3'>{item}</div>

                                <div className='tip-card mt-2'>
                                    <img className='w-100 cursor-pointer' src='/assets/images/comida_1.jpeg' onClick={()=>{
                                    navigate('/user/comida')
                                }}></img>
                                    <div className='f-14 text-green bolder p-1'>Salmon General</div>
                                    <div className='f-12 text-gray p-1'>Elimina la leche de vaca </div>
                                </div>
                        
                           
                            </div>
                        )
                    })
                }
               </div>
               <div className='f-16 bold text-gray ps-2'>Comdia</div>
               <div className='d-flex'>
               
                {
                    ['Domingo','Lunes','Martes','Miercoles','Jueves','Viernes','Sabado'].map((item, i)=>{
                        return(
                            <div key={i} className='col px-2'>
                                

                             
                        
                                <div className='tip-card mt-2'>
                                    <img className='w-100 cursor-pointer' src='/assets/images/comida_2.jpeg' onClick={()=>{
                                    setState({...state, recipe_active:1})
                                }}></img>
                                    <div className='f-14 text-green bolder p-1'>Sop</div>
                                    <div className='f-12 text-gray p-1'>¿Qué puedo hacer por mi, que nadie más hará?</div>
                                </div>
                        
                               
                            </div>
                        )
                    })
                }
               </div>
               <div className='f-16 bold text-gray ps-2'>Cena</div>
               <div className='d-flex'>
               
                
                {
                    ['Domingo','Lunes','Martes','Miercoles','Jueves','Viernes','Sabado'].map((item, i)=>{
                        return(
                            <div key={i} className='col px-2'>
                              

                            
                        
                                <div className='tip-card mt-3'>
                                    <img className='w-100 cursor-pointer' src='/assets/images/comida_3.jpeg' onClick={()=>{
                                    setState({...state, recipe_active:2})
                                }}></img>
                                    <div className='f-14 text-green bolder p-1'>Huevos al Gusto</div>
                                    <div className='f-12 text-gray p-1'>¿Qué puedo hacer por mi, que nadie más hará?</div>
                                </div>
                            </div>
                        )
                    })
                }
               </div>


       


               
              
            </div>
        </div> */}


        <div className='container px-3'>
            <div className='d-flex'>
                
                {/* {
                    ['Domingo','Lunes','Martes','Miercoles','Jueves', 'Viernes', 'Sabado'].map((item, i)=>{
                    
                        return( 
                            <div className='col pe-2'>
                            
                                <div className={`daytile--title ${state.init_date.getDay() === i ? 'bg-gray-00':''}`}>
                                    <div className='text-gray  f-14'>{item}</div>
                                    <div className='text-dark f-16 bold'>{getRestDays(state.init_date.getDay(),state.init_date.getDate(), i)}</div>
                                </div>
                            </div> 
                        )
                    })
                } */}

                {/* <div className='p-3' style={{width:100}}>
                    <div className='text-gray f-12 bold line-0'>EST <br></br> GMT-5</div>
                </div> */}

            </div>


            {
                time.map((item, i)=>{
                
                    return (
                        <div key={i} className='d-flex'>
                            
                            {[0,1,2,3,4,5,6].map((tile, j)=>{
                                let tile_date = getTileDate(state.init_date.getDay(),state.init_date.getDate(), j)
                           
                                tile_date.setHours(times[i],'00','00');
                                console.log(TileRecipeCheck(tile_date));

                                let recipe = TileRecipeCheck(tile_date)

                                

                                
                                
                                return (
                                    <div key={j} className='col pe-2'>


                                       
                                            
                                      

                                            {/* {i}
                                             */}
                                             
                                            <Renderif isTrue={recipe === undefined}>
                                                  <Renderif isTrue={i===0}>

                                                    <div className='col pe-2'>
                                                        <div className={`daytile--title ${state.init_date.getDay() === i ? 'bg-gray-00':''}`} onClick={()=>{
                                                            navigate('/user/comidas', {state:{day_active:tile_date}})
                                                        }}>
                                                            <div className='text-gray  f-14'>{weekday[j]}</div>
                                                            <div className='text-dark f-16 bold'>{getRestDays(state.init_date.getDay(),state.init_date.getDate(), j)}</div>
                                                        </div>
                                                    </div> 
        
                                               
                                                </Renderif>
                                                
                                                <div className='h-100 bg-gray-00 p-1 mt-2'></div>
                                            </Renderif>
                                            <Renderif isTrue={recipe !== undefined}>

                                          

                                          


                                                <Renderif isTrue={i===0}>

                                                    <div className='col pe-2'>
                                                        <div className={`daytile--title ${state.init_date.getDay() === i ? 'bg-gray-00':''}`} onClick={()=>{
                                                            navigate('/user/comidas', {state:{day_active:tile_date}})
                                                        }}>
                                                            <div className='text-gray  f-14'>{weekday[j]}</div>
                                                            <div className='text-dark f-16 bold'>{getRestDays(state.init_date.getDay(),state.init_date.getDate(), j)}</div>
                                                        </div>
                                                    </div> 
        
                                                    <div className='tip-card mt-2'>
                                                        <div className='tip-thumb-image' style={{backgroundImage:` url(https://mydiet-assets-2023.s3.amazonaws.com/${ state.allRecipes.find((item)=> item.id === recipe?.breakfast?.id)?.image?.name})`}}></div>
                                                        <div className='f-14 text-green bolder p-1'>{recipe?.breakfast?.name}</div>
                                                        <div className='f-14 text-gray p-1 text-ellipsis-2'>{recipe?.breakfast?.preparation}</div>
                                                    </div>
                                                </Renderif>
                                                <Renderif isTrue={i===1}>
                                            
                                                    <div className='tip-card mt-2'>
                                                        <div className='tip-thumb-image' style={{backgroundImage:` url(https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item)=> item.id === recipe?.meal?.id)?.image?.name})`}}></div>
                                                        <div className='f-14 text-green bolder p-1'>{recipe?.meal?.name}</div>
                                                        <div className='f-14 text-gray p-1 text-ellipsis-2'>{recipe?.meal?.preparation}</div>
                                                    </div>
                                                </Renderif>
                                                <Renderif isTrue={i===2}>
                                            
                                                    <div className='tip-card mt-2'>
                                                        <div className='tip-thumb-image' style={{backgroundImage:` url(https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item)=> item.id === recipe?.dinner?.id)?.image?.name})`}}></div>
                                                        <div className='f-14 text-green bolder p-1'>{recipe?.dinner?.name}</div>
                                                        <div className='f-14 text-gray p-1 text-ellipsis-2'>{recipe?.dinner?.preparation}</div>
                                                    </div>
                                                </Renderif>
                                          
                                       
                                             
                                            </Renderif>
                                            
                                       
                                            
                                    
                                    </div>
                                )
                            })}
                        
                            {/* <div className='p-3' style={{width:100}}>
                                <div className='text-gray f-12 bold line-0'>{item}</div>
                            </div> */}
                        

                        </div>
                    )
                })
            }
        </div>


    </div>
        
    </div>
  )
}
