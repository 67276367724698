import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RecoverPass } from '../services/api'
import { AuthContext } from '../context/auth_context'
import { AlertContext } from '../context/alert_context'

export default function RecoverPassView() {
  const navigate = useNavigate()

  const {setUser} = useContext(AuthContext)
  const {notify} = useContext(AlertContext)
  
  const [state, setState] = useState({
    email:''
  })

  const onRecoverPass =async () => {
    let user = await RecoverPass({...state})
    console.log(user)
    if(user.status !== 200){
      notify({title:'Error!', message:'Ocurrio un error al iniciar sesión, por favor intenta de nuevo más tarde.', type:'error'})
      return
    }
    notify({title:'Notificación!', message:'En breve recibirás un correo electrónico con las instrucciones a seguir.', type:'success'})
    // return
    navigate('/')
  }

  return (
    <div className='login_view container-fluid'>

      <div className='container'>
        <img className='d-block mx-auto pt-5' width={250} src='assets/images/logo.png'></img>
      </div>

      <div className='login_view_form'>

        <div className='mt-2'>
          <div className='text-gray'>Correo electrónico</div>
          <div>
            <input value={state.email} onChange={(evt)=>{setState({...state, email:evt.currentTarget.value})}} className='input--light' type='text'></input>
          </div>
        </div>
       <div className='mt-3'>
        <div className='bv-cta bv-cta--success w-100' onClick={()=>{
          onRecoverPass();
          
        }}>
            <div>Recuperar contraseña</div>
          </div>
       </div>

       <div className='mt-3'>
       <div className='text-decoration-underline text-gray cursor-pointer text-center' onClick={()=>{
          navigate('/')
        }}>
        Cancelar 
       </div>
       </div>

      </div>

    </div>
  )
}
