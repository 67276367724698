import React, { useContext, useEffect, useState } from 'react'
import SideMenu from '../components/sidemenu'
import { useNavigate } from 'react-router-dom';
import { Service } from '../services/api';
import { AuthContext } from '../context/auth_context';
import Renderif from '../components/renderif';
import { AiFillCloseCircle } from 'react-icons/ai'

export default function HomeView() {
    const navigate = useNavigate();

    const { user, logout } = useContext(AuthContext)

    const [state, setState] = useState({
    })

    useEffect(() => {

        return () => { }
    }, [])

    return (
        <div className='container-fluid d-flex h-100'>

            <div>
                <SideMenu page={'home'}></SideMenu>
            </div>

            <div className='col h-100 overflow-auto hide-scroll'>

                <div className='container-fluid bg-white sticky-top '>
                    <img className='m-3' width={120} src='../assets/images/logo.png'></img>
                </div>

                <div className='container-fluid container-xl mx-auto p-3'>
                    <img className='home-banner' src='../assets/images/home.png'></img>
                </div>
                <div className='container-fluid container-xl mx-auto'>
                    <div className='d-flex grap'>
                        <div className='cursor-pointer' onClick={() => {
                            navigate('/user/nutriologia')
                        }}>
                            <img className='home-banner-category p-2' src='../assets/images/home_nutri.png'></img>
                        </div>
                        <div className='cursor-pointer' onClick={() => {
                            navigate('/user/ginecologia')
                        }}>
                            <img className='home-banner-category p-2' src='../assets/images/home_ginecologia.png'></img>
                        </div>
                        <div className='cursor-pointer' onClick={() => {
                            navigate('/user/psicologia')
                        }}>
                            <img className='home-banner-category p-2' src='../assets/images/home_psicologia.png'></img>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}
