import React, { useContext } from 'react'
import { BsCheck } from 'react-icons/bs';
import { IoWarning } from 'react-icons/io5';
import { MdError } from 'react-icons/md';
import { HiCursorArrowRays } from 'react-icons/hi2';

import { AlertContext } from '../context/alert_context';
import { green, primary, yellow } from '../utils/color';
export default function AlertNotification() {
    
    const {notifications} = useContext(AlertContext)


    const icon = (type) => {
        switch (type) {
            case 'success':
                return (<BsCheck size={30} color={green}></BsCheck>)
                break;
            case 'error':
                return ( <MdError size={28} color={'red'}></MdError>)
               
         
        
            default:
              return <></>
        }
    }
    
  return (
    <div className='bv-alert-notification-holder'>

        {
            notifications.map((item, i)=>{
                return (
                    <div key={i} className='bv-alert-notification'>
                        <div className='d-flex'>
                            <div className='bv-icon bv-icon--big'>
                                {icon(item.type)}
                                
                            </div>
                        
                            <div className='d-flex flex-column justify-content-center'>
                                <div className='text-dark f-14 bold line-0'>{item.title}</div>
                                <div className='text-dark f-14'>{item.message}</div>
                            </div>
                        </div>
                    </div>
                )
            })

        }

        {/* <div className='bv-alert-notification d-none'>
            <div className='d-flex'>
                <div className='bv-icon bv-icon--big'>
                    <BsCheck size={30} color={green}></BsCheck>
                </div>
            
                <div className='d-flex flex-column justify-content-center'>
                    <div className='text-dark f-16 bold line-0'>Listo!</div>
                    <div className='text-dark f-14'>Lorem impsum is a symple.</div>
                </div>
            </div>
        </div>

        <div className='bv-alert-notification d-none'>
            <div className='d-flex'>
                <div className='bv-icon bv-icon--big'>
                    <IoWarning size={30} color={yellow}></IoWarning>
                </div>
            
                <div className='d-flex flex-column justify-content-center'>
                    <div className='text-dark f-16 bold line-0'>mmh!</div>
                    <div className='text-dark f-14'>Lorem impsum is a symple.</div>
                </div>
            </div>
        </div>

        <div className='bv-alert-notificatio d-none'>
            <div className='d-flex'>
                <div className='bv-icon bv-icon--big'>
                    <MdError size={28} color={'red'}></MdError>
                </div>
            
                <div className='d-flex flex-column justify-content-center'>
                    <div className='text-dark f-16 bold line-0'>Error!</div>
                    <div className='text-dark f-14'>Lorem impsum is a symple.</div>
                </div>
            </div>
        </div>

        <div className='bv-alert-notification d-none'>
            <div className='d-flex'>
                <div className='bv-icon bv-icon--big'>
                    <HiCursorArrowRays size={30} color={primary}></HiCursorArrowRays>
                </div>
            
                <div className='col d-flex flex-column justify-content-center'>
                    <div className='text-dark f-16 bold line-0'>Confirmar ? </div>
                    <div className='text-dark f-14'>Lorem impsum is a symple.</div>

                    <div className='d-flex flex-row mt-3'>
                        <div className='col'></div>
                        <div className='bv-pill bv-pill--gray'>Cancelar</div>
                        <div className='mx-1'></div>
                        <div className='bv-pill bv-pill--primary'>Confirmar</div>
                    </div>

                </div>
            </div>
        </div> */}

    </div>
  )
}
