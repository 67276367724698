import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChangePass } from '../services/api'
import { AuthContext } from '../context/auth_context'
import { AlertContext } from '../context/alert_context'
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import Renderif from '../components/renderif'

export default function ResetPassView() {
  const navigate = useNavigate()

  const {setUser} = useContext(AuthContext)
  const {notify} = useContext(AlertContext)
  const currentUrl = window.location.search;
  const searchParams = new URLSearchParams(currentUrl);

  
  const [state, setState] = useState({
    pass:'',
    passCofirmed :'',
    showPassword: false
  })
  const checkPassword = (password) => {
    var re = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-.#]).{8,}$/;
    return re.test(password);
  }
  
  const onResetPass =async () => {
    if (
      state.pass === '' ||
      state.passCofirmed === ''

    ) {
      notify({ title: 'Error', message: 'Todos los campos son obligatorios', type: 'error' })
      return;
    }

    if (state.pass !== state.passCofirmed) {
      notify({ title: 'Registro', message: 'Las contraseñas no coinciden', type: 'error' })
      return;
    }

    if (!checkPassword(state.passCofirmed)) {
      // console.log("validat")
      // setState({...state, error:'La contraseña debe contener 8 caracteres al menos una letra mayuscula, minuscula, numero, caracter especial, #?!@$%^&*-.#'})
      notify({ title: 'Registro', message: 'La contraseña debe contener 8 caracteres, al menos una letra mayúscula, minúscula y un símbolo.', type: 'error' })
      return;
    }
    // console.log(state.passCofirmed)
    let user = await ChangePass(searchParams.get("u"),{"password":state.passCofirmed})
    if(user.status !== 200){
      notify({title:'Error!', message:'Ocurrio un error al crear contraseña, por favor intenta de nuevo más tarde.', type:'error'})
      return
    }
    notify({title:'Notificación!', message:'Introduce tu contraseña nuevamente para iniciar sesión.', type:'success'})
    navigate('/')
  }

  return (
    <div className='login_view container-fluid'>

      <div className='container'>
        <img className='d-block mx-auto pt-5' width={250} src='assets/images/logo.png'></img>
      </div>

      <div className='login_view_form'>

        <div className='mt-2'>
          <div className='text-gray'>Nueva contraseña</div>
          <div className='bv-icon-v2'>
            <input value={state.pass} onChange={(evt) => { setState({ ...state, pass: evt.currentTarget.value }) }} className='input--light' type={`${state.showPassword ? 'text': 'password'}`}></input>
            <Renderif isTrue={state.showPassword === true}>
              <div className='bv-icon bv-icon--mid mx-2 mb-2 cursor-pointer' onClick={() => {

                setState({ ...state, showPassword: false })

              }}>
                <BsEye size={30}></BsEye>
              </div>
            </Renderif>

            <Renderif isTrue={state.showPassword === false}>
              <div className='bv-icon bv-icon--mid mx-2 mb-2 cursor-pointer' onClick={() => {

                setState({ ...state, showPassword: true })

              }}>
                <BsEyeSlash size={30}></BsEyeSlash>
              </div>
            </Renderif>
          </div>
        </div>

        <div className='mt-2'>
          <div className='text-gray'>Confirmar contraseña</div>
          <div className='bv-icon-v2'>
            <input value={state.passCofirmed} onChange={(evt) => { setState({ ...state, passCofirmed: evt.currentTarget.value }) }} className='input--light' type={`${state.showPassword ? 'text': 'password'}`}></input>
            <Renderif isTrue={state.showPassword === true}>
              <div className='bv-icon bv-icon--mid mx-2 mb-2 cursor-pointer' onClick={() => {

                setState({ ...state, showPassword: false })

              }}>
                <BsEye size={30}></BsEye>
              </div>
            </Renderif>

            <Renderif isTrue={state.showPassword === false}>
              <div className='bv-icon bv-icon--mid mx-2 mb-2 cursor-pointer' onClick={() => {

                setState({ ...state, showPassword: true })

              }}>
                <BsEyeSlash size={30}></BsEyeSlash>
              </div>
            </Renderif>
          </div>
        </div>

       <div className='mt-3'>
        <div className='bv-cta bv-cta--success w-100' onClick={()=>{
          onResetPass();
          
        }}>
            <div>Aceptar</div>
          </div>
       </div>

       <div className='mt-3'>
       <div className='text-decoration-underline text-gray cursor-pointer text-center' onClick={()=>{
          navigate('/')
        }}>
        Cancelar 
       </div>
       </div>

      </div>

    </div>
  )
}
