import React, { useContext, useEffect, useState } from 'react'
import { createContext } from "react";
import { useNavigate } from 'react-router-dom';
import { Service } from '../services/api';
import { AlertContext } from './alert_context';
export const AuthContext = createContext();

export default function AuthContextProvider({children}) {

  const navigate = useNavigate();
  const {notify} = useContext(AlertContext);

    const [state, setState] = useState({
        user:'',
        loading:false
    })

    useEffect(()=>{

      let token = localStorage.getItem('token');

      const init = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        
  
  
        // if(searchParams.get('isSubscribed') === 'true'){


            let _me = await Service('POST', 'auth/me')
            if(_me.status === 200){
            
              setState({...state, user:_me.data})
            }
            console.log(_me)
        // }
        
        // else{
        //   if(token !== null && state.loading === false){
        //     console.log("USER ACTIVE");
        //     setState({...state, loading:true})
        //     await login({token});
        //   }
        // }
       
      };
      init();

      return () => {};
      
    },[])


 
    const setUser = ({user}) => {
      console.log(user);

      user !== null ? localStorage.setItem('token', user.token) : localStorage.removeItem('token');

      setState({...state, user})
    }

    // const auth = async ({token}) => {
    //   console.log("JUAN");
    //   let user = await Service('POST','Auth/verify_token', {'api_token':token})
   
    //   state.loading = false
    //     if(user.status == 200){
    //       let profiles = localStorage.getItem('tokens')
    //       profiles = JSON.parse(profiles);
    //       user.data.profiles = profiles
    //       setUser({user:user.data})

       
    //   }else{
    //     notify({title:'error', message:'Tu sesión ha expirado', type:'error'})
    //     localStorage.removeItem('token');
    //     localStorage.removeItem('tokens');
    //     navigate('/');
    //   }
    // }

    const login = async ({token}) => {
      console.log("USER SET");
      let user = await Service('POST','auth/verify_token', {'api_token':token})
   
      state.loading = false
      if(user.status == 200){
          let profiles = localStorage.getItem('tokens')
          profiles = JSON.parse(profiles);
          user.data.profiles = profiles
          setUser({user:user.data})

        

          // if(user.data.roles.includes("Admin")){
          //   console.log("1")
          //   navigate('/administrador');
          //   return;
          // }
          // if(user.data.roles.includes("Psicologist")){
          //   console.log("2")
          //   navigate('/admin');
          //   return
          // }
          // navigate('/client');
       
      }else{
        notify({title:'error', message:'Tu sesión ha expirado', type:'error'})
        localStorage.removeItem('token');
        localStorage.removeItem('tokens');
        navigate('/');
      }
    }

    // const changeUser = async ({profile}) => {


  
    //   localStorage.setItem('token',profile.api_token);
    //   let token = localStorage.getItem('token');

   


    
    //   let user = await Service('POST','Auth/verify_token', {'api_token':token})

    //   if(user.status === 200){
    //     let profiles = localStorage.getItem('tokens');
   
        

    //     user.data.profiles = JSON.parse(profiles);
    //     console.log(user.data)
    //     setUser({user:user.data})
    //     return true;
    //   }
     
    //   return false;
    // }



    const logout = () => {
      setUser({user:null});
      // localStorage.removeItem('token');
      // localStorage.removeItem('tokens');
      // localStorage.removeItem('onboarding');
    }


  return (
    <AuthContext.Provider value={
      {
        ...state,
        logout,
        setUser,
        // changeUser,
        // auth
      }
    }>

        {children}

    </AuthContext.Provider>
  )
}
