import React, { useEffect, useState } from 'react'
import SideMenu from '../components/sidemenu'
import { useLocation, useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi";
import axios from 'axios';
import Renderif from '../components/renderif';
import { BsFilePdf } from 'react-icons/bs';
import { FaFilePdf, FaRegFilePdf } from 'react-icons/fa6';
import { AiOutlineTags } from 'react-icons/ai';

export default function PlanDetail() {
    const navigate = useNavigate();

    const location = useLocation()

    const item = location.state.item
    console.log(item)


    const [state, setState] = useState({
        tips: {
            items: [[], [], [], []],
            includes: []
        },
        videos: [],
        tab: 0
    })

    useEffect(() => {
        init();
        return () => { }
    }, [])




    const init = async () => {
        const ACCESS_TOKEN = "rY46Ylrq96wuj2fPp90ok5Ku96C0LctV3j-furqSwCM";
        const SPACE_ID = "cyupqx8f0n3c";
        let tags = ''

        item.category.map((item) => {
            tags = tags + item.toLowerCase() + ','
        });
        
        console.log(tags)

        let tipId='';
        let videoId='';

        switch (item.specialty) {
            case "PSYCHOLOGY":
                tipId="tipPsicologia";
                videoId="videoPsicologia";
              break;
            case "GYNECOLOGY":
                tipId="tipGinecologia";
                videoId="videoGinecologia";
              break
            default:
                tipId="tip";
                videoId="video";
              break
          }
          

        const { data } = await axios.get(
            `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/testing/entries`,
            {
                //   withCredentials: false,
                baseURL: "",
                params: {
                    content_type: tipId,
                    access_token: ACCESS_TOKEN,
                    // "metadata.tags.sys.id[in]": tags,
                },
            }

        );
        console.log(data)


        // const { videos } = await axios.get(
        const videos = await axios.get(
            `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/testing/entries`,
            {
                //   withCredentials: false,
                baseURL: "",
                params: {
                    content_type: videoId,
                    access_token: ACCESS_TOKEN,
                    // "metadata.tags.sys.id[in]": tags,
                },
            }

        );
        console.log(videos.data.items)
        state.videos = videos.data.items;

        const perChunk = 16 / 4 // items per chunk    

        const inputArray = ['a', 'b', 'c', 'd', 'e']

        const items = data.items.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, [])



        state.tips.items = items;
        state.includes = data.includes

        // console.log(data.includes.Asset)
        // console.log(data.items)
        // console.log(data.includes.Asset.find((include) => include.sys.id == "1HvUMnRyLOoPiN1kLLRVx2"));

        setState({ ...state })


    }


    return (
        <div className='container-fluid d-flex h-100'>

            <div>
                <SideMenu page={'home'}></SideMenu>
            </div>

            <div className='col h-100 overflow-auto hide-scroll'>

                <div className='container-fluid bg-white sticky-top'>
                    <img className='m-3' width={120} src='/assets/images/logo.png'></img>
                </div>

                <div className='container p-3'>

                    <div className='cta-back' onClick={() => {
                        navigate(-1)
                    }}>
                        <div className='bv-icon bv-icon--mid'>
                            <BiArrowBack size={18} color='#00bf6b'></BiArrowBack>
                        </div>
                        <div className='f-14 text-green'>Regresar</div>
                    </div>


                    <div className='f-28 text-gray bold line-0'>{item.title}</div>
                    <div className='f-16 text-gray line-0'>Tips y recetas sencillas.</div>
                    <div className='tags'>

                        {[...item.category, ...item.subCategory].map((item, i) => {
                            return (
                                <div key={i} className='bv-tag bv-tag--gray'>
                                    <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                                    <div className='text-gray pe-2'>{item}</div>
                                </div>
                            )
                        })}



                    </div>
                </div>

                <div className='container px-3 mt-3'>
                    <div className='tabs'>
                        <div className={`bv-tab ${state.tab === 0 ? 'bv-tab--active' : ''}`} onClick={() => { setState({ ...state, tab: 0 }) }}>Tips</div>
                        <div className={`bv-tab ${state.tab === 1 ? 'bv-tab--active' : ''}`} onClick={() => { setState({ ...state, tab: 1 }) }}>Videos</div>
                        <div className={`bv-tab ${state.tab === 2 ? 'bv-tab--active' : ''}`} onClick={() => { setState({ ...state, tab: 2 }) }}>Aprende más</div>

                    </div>
                </div>
                <Renderif isTrue={state.tab === 0}>
                    <div className='container px-3 mt-3'>
                        <div className='row'>
                            <div className='col-12 col-md-6 col-xl-3 pe-2'>


                                {
                                    state.tips.items[0].map((tip, i) => {

                                        // console.log(tip)

                                        let _image_sys = state.includes.Asset.find((include) => include?.sys?.id === tip?.fields?.imagen.sys.id)

                                        return (
                                            <div className='tip-card mb-2'>



                                                <img className='w-100 cursor-pointer' src={_image_sys?.fields?.file?.url} onClick={() => {
                                                    navigate('/user/tip', { state: { tip, includes: _image_sys } })
                                                }}></img>
                                                <div className='f-16 text-green bolder p-1'>{tip.fields.title}</div>
                                                <div className='f-14 text-gray p-1'>{_image_sys?.fields?.description}</div>
                                            </div>
                                        )
                                    })
                                }


                            </div>
                            <div className='col-12 col-md-6 col-xl-3 pe-2'>
                                {
                                    state.tips.items[1].map((tip, i) => {

                                        // console.log(tip)
                                        // console.log(state.includes.Asset.find((include) => include?.sys?.id === tip?.fields?.imagen.sys.id));
                                        let _image_sys = state.includes.Asset.find((include) => include?.sys?.id === tip?.fields?.imagen.sys.id)

                                        return (
                                            <div className='tip-card mb-2'>
                                                <img className='w-100 cursor-pointer' src={_image_sys?.fields?.file?.url} onClick={() => {
                                                    navigate('/user/tip', { state: { tip, includes: _image_sys } })
                                                }}></img>
                                                <div className='f-16 text-green bolder p-1'>{tip.fields.title}</div>
                                                <div className='f-14 text-gray p-1'>{_image_sys?.fields?.description}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='col-12 col-md-6 col-xl-3 pe-2'>
                                {
                                    state.tips.items[2].map((tip, i) => {

                                        // console.log(tip)
                                        // console.log(state.includes.Asset.find((include) => include?.sys?.id === tip?.fields?.imagen.sys.id));
                                        let _image_sys = state.includes.Asset.find((include) => include?.sys?.id === tip?.fields?.imagen.sys.id)

                                        return (
                                            <div className='tip-card mb-2'>
                                                <img className='w-100 cursor-pointer' src={_image_sys?.fields?.file?.url} onClick={() => {
                                                    navigate('/user/tip', { state: { tip, includes: _image_sys } })
                                                }}></img>
                                                <div className='f-16 text-green bolder p-1'>{tip.fields.title}</div>
                                                <div className='f-14 text-gray p-1'>{_image_sys?.fields?.description}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='col-12 col-md-6 col-xl-3 pe-2'>
                                {
                                    state.tips.items[3].map((tip, i) => {

                                        // console.log(tip)
                                        // console.log(state.includes.Asset.find((include) => include?.sys?.id === tip?.fields?.imagen.sys.id));
                                        let _image_sys = state.includes.Asset.find((include) => include?.sys?.id === tip?.fields?.imagen.sys.id)

                                        return (
                                            <div className='tip-card mb-2'>
                                                <img className='w-100 cursor-pointer' src={_image_sys?.fields?.file?.url} onClick={() => {
                                                    navigate('/user/tip', { state: { tip, includes: _image_sys } })
                                                }}></img>
                                                <div className='f-16 text-green bolder p-1'>{tip.fields.title}</div>
                                                <div className='f-14 text-gray p-1'>{_image_sys?.fields?.description}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </Renderif>
                <Renderif isTrue={state.tab === 1}>
                    <div className='container px-3 mt-3'>
                        <div className='row'>
                                {
                                    state.videos.map((video, i) => {
                                        console.log(video)
                                        return (
                                            <div className='tip-card mb-2 m-2 tip-card-video'>
                                                <iframe class="w-100" src={video?.fields?.videoyoutube} allowfullscreen></iframe>
                                                {/* <div className='f-16 text-green bolder p-1'>{video?.fields?.title}</div> */}
                                                <div className='f-16 text-gray p-1'>{video?.fields?.title}</div>
                                            </div>
                                        )
                                    })
                                }
                        </div>
                    </div>
                </Renderif>
                <Renderif isTrue={state.tab === 2}>
                    <div className='container px-3 mt-3'>
                        <div className='d-flex flex-wrap'>
                            {
                                item.documents.map((doc) => {
                                    // console.log(doc)
                                    return (
                                        <div className='d-flex justify-content-center align-items-center flex-column me-2'>
                                            <div className='bv-icon bv-icon--xl card cursor-pointer m-1' onClick={() => {
                                                window.open(`https://mydiet-assets-2023.s3.amazonaws.com/${doc.name}`, '_blank')
                                            }}>
                                                <FaRegFilePdf color='#00bf6b' size={40}></FaRegFilePdf>
                                            </div>
                                            <div className='f-12 bold text-gray'>{doc?.infoName}</div>
                                        </div>
                                    )
                                })
                            }

                            {/* <div className='bv-icon bv-icon--xl card cursor-pointer m-1'>
                        <FaRegFilePdf color='#00bf6b' size={40}></FaRegFilePdf>
                    </div>
                    <div className='bv-icon bv-icon--xl card cursor-pointer m-1'>
                        <FaRegFilePdf color='#00bf6b' size={40}></FaRegFilePdf>
                    </div> */}

                        </div>
                    </div>
                </Renderif>


            </div>

        </div>
    )
}
