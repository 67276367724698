import React from 'react'
import Calendar from 'react-calendar'

export default function BVCalendar(props) {

  let date = new Date();
  date.setDate(date.getDate() + 1)




  

  return (
    <div className='bv-calendar p-2'>
        <Calendar

        
        value={props.date}

        onChange={(evt)=>{

          console.log(props.date)

          console.log(evt)
          if(props.date === null){
            props.onChange(evt);
            return;
          }
          

          if(evt?.getTime() === props.date?.getTime()){
            props.onChange(null)
          }else{
            props.onChange(evt)
          }

     

          

        }}
        showNeighboringMonth={false}
        tileClassName={['bv-calendar-day']}
        ></Calendar>
    </div>
  )
}
// react-calendar__tile react-calendar__month-view__days__day
// react-calendar__tile react-calendar__month-view__days__day

// react-calendar__tile react-calendar__tile--active react-calendar__tile--range react-calendar__tile--rangeStart react-calendar__tile--rangeEnd react-calendar__tile--rangeBothEnds react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend
