import React from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

export default function BackBtn({text = "Regresar", onClick=null}) {
    const navigate = useNavigate()
  return (
    <div className='cta-back' onClick={()=>{
        if(onClick === null){
            navigate(-1)
        }else{
            onClick();
        }
    }}>
        <div className='bv-icon bv-icon--mid'>
            <BiArrowBack size={18} color='#00bf6b'></BiArrowBack>
        </div>
        <div className='f-14 text-green'>{text}</div>
    </div>
  )
}
