import React, { useContext, useState } from 'react'
import SideMenu from '../components/sidemenu'
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi";
import Renderif from '../components/renderif';
import { AuthContext } from '../context/auth_context';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Service } from '../services/api';

export default function PrefilView() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext)

  const [state, setState] = useState({
    tab: 0,
    show_cita_detail_id: null,
    family: [{
      img: 'https://source.boringavatars.com/beam/120/Stefan?colors=C0EFFF,f4a261',
      name: user?.firstName,
      lastname: user?.lastName,
      relationship: '',
      email: user.email,
      phone: user.cellPhone
    }],
    member_active: {
      img: 'https://source.boringavatars.com/beam/120/Stefan?colors=C0EFFF,f4a261',
      name: user?.firstName,
      lastname: user?.lastName,
      relationship: '',
      email: user?.email,
      phone: user?.cellPhone
    },
    add_new_member: false,
    edit_member: false,
    plan_cancel_modal: false,

  })
  return (
    <div className='container-fluid d-flex h-100'>

      <div>
        <SideMenu page={'perfil'}></SideMenu>
      </div>

      <Renderif isTrue={state.plan_cancel_modal}>
        <div className='overlay d-flex justify-content-center align-items-center'>
          <div className='bv-modal shadow'>
            <div className='px-4 pt-3 d-flex align-items-center'>
              <div className=' f-16 px-4 pt-3 text-gray bolder'>Cancelar Plan </div>
              <div className='col'></div>
              <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                setState({ ...state, plan_cancel_modal: false })

              }}> <AiFillCloseCircle size={24} color="#00BF6B"></AiFillCloseCircle></div>
            </div>
            <div className='px-4 pb-5'>

              <div className='px-4 pt-3 image-container'>
                <div className='f-18 text-gray bolder p-1'>Estás segur@ de cancelar tu plan? </div>
              </div>
              <div className='mt-3  d-flex align-items-center'>
                <div className={`bv-cta bv-cta--danger`} onClick={() => {
                  setState({ ...state, plan_cancel_modal: false })
                }}>
                  Lo pensare de nuevo

                </div>
                <div className='col'></div>
                <div className={`bv-cta bv-cta--success`} onClick={async()  => {
                  console.log("cancelanding");
                  let _planCancel = await Service('DELETE', 'plans/cancel-suscription');
                  console.log(_planCancel)
                  if (_planCancel.status === 200) {
                    navigate('/user')
                }
                  setState({ ...state, plan_cancel_modal: false })
                }}>
                  Cancelar Plan

                </div>
              </div>
            </div>

          </div>
        </div>
      </Renderif>

      <div className='col'>

        <div className='container-fluid bg-white'>
          <img className='m-3' width={120} src='/assets/images/logo.png'></img>
        </div>

        <div className='container  p-3'>

          {/* <div className='cta-back' onClick={()=>{
                navigate(-1)
            }}>
                <div className='bv-icon bv-icon--mid'>
                    <BiArrowBack size={18} color='#00bf6b'></BiArrowBack>
                </div>
                <div className='f-14 text-green'>Regresar</div>
            </div> */}


          <div className='d-flex align-items-center'>
            <div className='d-flex align-items-center'>
              <div className='avatar avatar--big'></div>
              <div className='ps-3'>
                <div className='f-28 text-gray bold line-0'>{user?.firstName}</div>
                <div className='f-14 text-gray line-0'>{user?.email}</div>
              </div>
            </div>

            <div className='col'></div>
            <div className='pe-3'>

              <Renderif isTrue={state.edit_member === false}>
                <div className='bv-cta bv-cta--success w-100' onClick={() => {
                  setState({ ...state, edit_member: true })
                }}>
                  <div>Editar</div>
                </div>
              </Renderif>

            </div>
            <div className='col-2'></div>

          </div>
        </div>
        {/* 
        <div className='container px-3'>
            <div className='tabs'>
                <div className='bv-tab bv-tab--active'>Tips</div>
                <div className='bv-tab'>Videos</div>
                <div className='bv-tab'>Aprende más</div>
            </div>
        </div> */}

        <div className='container px-3'>
          <div className='row'>

            <div className='col-7 pe-2'>
              <div className='d-flex bg-light r-5 p-3'>

                <div className='col'>

                  <div className=''>
                    <div className='text-dark f-14 bold mb-1 ps-1'>Nombre(s):</div>
                    <Renderif isTrue={!state.edit_member}>
                      <div className='text-gray f-14 ps-1'>{user.firstName}</div>
                    </Renderif>
                    <Renderif isTrue={state.edit_member}>
                      <input className='input--light' defaultValue={'Lupita'} onChange={(evt) => {
                        // state.member_active.name = evt.currentTarget.value;
                        // setState({...state})
                      }}></input>
                    </Renderif>
                  </div>


                  <div className='mt-3'>
                    <div className='text-dark f-14 bold mb-1 ps-1'>Teléfono:</div>
                    <Renderif isTrue={!state.edit_member}>
                      <div className='text-gray f-14 ps-1'>{user?.phone}</div>
                    </Renderif>
                    <Renderif isTrue={state.edit_member}>
                      <input className='input--light' defaultValue={'+52 443 252 88 91'} onChange={(evt) => {
                        // state.member_active.name = evt.currentTarget.value;
                        // setState({...state})
                      }}></input>
                    </Renderif>
                  </div>

                </div>
                <div className='col ps-3'>

                  <div className=''>
                    <div className='text-dark f-14 bold mb-1 ps-1'>Apellido(s):</div>
                    <Renderif isTrue={!state.edit_member}>
                      <div className='text-gray f-14 ps-1'>{user?.lastName}</div>
                    </Renderif>
                    <Renderif isTrue={state.edit_member}>
                      <input className='input--light' defaultValue={'Aldana'} onChange={(evt) => {
                        // state.member_active.name = evt.currentTarget.value;
                        // setState({...state})
                      }}></input>
                    </Renderif>
                  </div>

                </div>
              </div>

              <div className='d-flex flex-column bg-light r-5 p-3 mt-3'>
                <div className='f-16 bolder'>Suscripciones</div>
                <div>

                  {user?.subscriptions.map((item, i) => {
                    return (
                      <div key={i} className='d-flex mt-3'>
                        <div className='text-gray'> {item.name}</div>
                        <div className='col'></div>
                        <Renderif isTrue={state.edit_member === true}>
                          <div className='f-14 text-red text-decoration-underline cursor-pointer' onClick={() => {
                            setState({ ...state, plan_cancel_modal: true })
                          }}>
                            Cancelar
                          </div>
                        </Renderif>

                      </div>
                    )
                  })}

                </div>
              </div>



              <Renderif isTrue={state.edit_member === true}>
                <div className='d-flex mt-3'>
                  <div className='col'></div>
                  <div className='bv-cta bv-cta--danger' onClick={() => {
                    setState({ ...state, edit_member: false })
                  }}>
                    <div>Cancelar</div>
                  </div>
                  <div className='mx-1'></div>
                  <div className='bv-cta bv-cta--success' onClick={() => {
                    setState({ ...state, edit_member: false })
                  }}>
                    <div>Guardar</div>
                  </div>
                </div>
              </Renderif>




            </div>
            <div className='col-3 pe-2'>
              <div className='bg-light r-5 p-3'>

                <div className=''>
                  <div className='text-dark f-14 bold mb-1 ps-1'>Peso:</div>
                  <Renderif isTrue={!state.edit_member}>
                    <div className='text-gray f-14 ps-1'>52 Kg</div>
                  </Renderif>
                  <Renderif isTrue={state.edit_member}>
                    <input className='input--light' defaultValue={'52 Kg'} onChange={(evt) => {
                      state.member_active.name = evt.currentTarget.value;
                      setState({ ...state })
                    }}></input>
                  </Renderif>
                </div>

                <div className='mt-2'>
                  <div className='text-dark f-14 bold mb-1 ps-1'>Estarura:</div>
                  <Renderif isTrue={!state.edit_member}>
                    <div className='text-gray f-14 ps-1'>164 Cm</div>
                  </Renderif>
                  <Renderif isTrue={state.edit_member}>
                    <input className='input--light' defaultValue={'164 Cm'} onChange={(evt) => {
                      state.member_active.name = evt.currentTarget.value;
                      setState({ ...state })
                    }}></input>
                  </Renderif>
                </div>

                <div className='mt-2'>
                  <div className='text-dark f-14 bold mb-1 ps-1'>Padecimientos:</div>
                  <Renderif isTrue={!state.edit_member}>
                    <div className='text-gray f-14 ps-1'>Ninguno</div>
                  </Renderif>
                  <Renderif isTrue={state.edit_member}>
                    <input className='input--light' defaultValue={'Ninguno'} onChange={(evt) => {
                      state.member_active.name = evt.currentTarget.value;
                      setState({ ...state })
                    }}></input>
                  </Renderif>
                </div>

                <div className='mt-2'>
                  <div className='text-dark f-14 bold mb-1 ps-1'>Alergias:</div>
                  <Renderif isTrue={!state.edit_member}>
                    <div className='text-gray f-14 ps-1'>Ninguna</div>
                  </Renderif>
                  <Renderif isTrue={state.edit_member}>
                    <input className='input--light' defaultValue={'Ninguna'} onChange={(evt) => {
                      state.member_active.name = evt.currentTarget.value;
                      setState({ ...state })
                    }}></input>
                  </Renderif>
                </div>

              </div>

            </div>

          </div>
        </div>


      </div>

    </div>
  )
}
